<template>
  <div class="login-box">
    <!--<navlangbar />-->
    <section class="login">
      <van-sticky :offset-top="123">
        <van-image :src="bg2"> </van-image>
        <div class="login-input">
          <van-tabs v-model="active" color="#13b5b1" @click="onClick(active)">
            <van-tab :title="$t('Mt4ApiMenu.loginToCrm')"></van-tab>
            <van-tab :title="$t('Mt4ApiMenu.loginToMt4')"></van-tab>
          </van-tabs>
          <van-cell-group :border="false">
            <van-field
              v-model="mt4Account"
              type="number"
              :border="false"
              :placeholder="$t('app.pleaseinputaccount')"
              ref="mt4Account"
                       v-on:keyup.13="login"
            >
            </van-field>
            <van-field
              :border="false"
              v-model="pwd"
              center
              :placeholder="$t('app.pleaseinputpassword')"
              ref="pwd"
              type="password"
              v-on:keyup.13="login"
            >
            </van-field>
            <van-row style="padding: 8px 0; color: #13b5b1">
              <van-col span="24" style="font-size: 12px; text-align: right"
                ><router-link to="/retrievePwd">{{$t('app.refundpassword')}}</router-link></van-col
              >
            </van-row>
          </van-cell-group>

          <van-button
            :type="type"
            block
            :disabled="loginDisabled"
            
            :loading-text="$t('loading')"
            style="background-color: #13b5b1; border-radius: 30px"
            @click="login"
            >{{ $t("app.login") }}</van-button
          >
        </div>
        <van-row class="why">
          <van-col span="13" style="font-size: 12px">
            <!--{{$t('app.noaccount')}}?<span>&nbsp;&nbsp;{{$t('app.registernow')}}</span>--></van-col
          >
          <Navlangbar></Navlangbar>
        </van-row>
      </van-sticky>
    </section>
  </div>
</template>

<script>
import Navlangbar from "@/components/mt4/NavlangBar";
import { LoginMt4 } from "@/api/mt4Api";
import { vantLocales } from "@/lang";
    import { Notify, Dialog } from 'vant';
export default {
  name: "LoginByPwd",
  components: { Navlangbar },
  data() {
    return {
      changeLanguages: false,
      active: 1,
      type: "default",
      mt4Account: "",
      pwd: "",
      loginDisabled: "disabled",
      isLoading: false,
      /*logo: require("../../assets/image/login/login-logo.png"),*/
      bg: require("../../assets/image/login/login-bg.jpg"),
      bg2: require("../../assets/image/login/login-bg2.png"),
    };
  },
  created() {
    
  },
  mounted() {
    var h = document.documentElement.clientHeight || document.body.clientHeight;
    document.getElementsByClassName("login-box")[0].style.height = h + "px";

    this.$store.commit("toggleTabbarShow", false);
    this.$store.commit("toggleTabbarCurrentShow", "Mt4");
    if (!this.mt4Account) {
      this.$refs.mt4Account.focus();
    } else {
      this.$refs.pwd.focus();
            }
        },
  watch: {
    mt4Account(newVal, oldVal) {
      if (this.mt4Account.length > 0) {
        this.loginDisabled = false;
        this.type = "info";
      } else {
        this.loginDisabled = "disabled";
        this.type = "info";
      }
    },
    pwd(newVal, oldVal) {
      if (this.pwd.length > 0) {
        this.loginDisabled = false;
        this.type = "info";
      } else {
        this.loginDisabled = "disabled";
        this.type = "default";
      }
    },
  },
  methods: {
    changeLanguage() {
      this.changeLanguages = !this.changeLanguages;
    },
    onClick(index) {
      //console.log(index);
      if (index == 0) {
        //console.log(this);
        this.$router.push("/loginbypwd");
      }
    },
    login() {
      this.isLoading = true;
      localStorage.removeItem("tokenMt4");
      localStorage.removeItem("mt4userinfo");
      // this.$router.push("/settings")
      let params = {
         login: this.mt4Account,
         password: this.pwd,
         ip: "47.52.200.108:443",
      };

      //let params = {
      //  login: 25817,
      //  password: "qwe123",
      //  ip: "47.52.200.108:443",
      //};

      LoginMt4(params).then((res) => {
        if (res.code == 200) {
          this.$store.commit("loginMt4", res.msg);
          this.$store.commit("toggleTabbarShow", true);
          this.$router.push("/settings");
        } else {
          this.isLoading = false;
            Notify({ type: 'danger', message: this.$t(res.msg) });
          this.$refs.pwd.focus();
        }
      });
    },
  },
};
</script>

<style>
.login-box {
  background: url("../../assets/image/login/login-bg.jpg") no-repeat;
  background-size: cover;
}
.login-box .van-cell-group {
  width: 73%;
  z-index: 999;
}
.login .van-button--disabled {
  z-index: 999;
}
.login {
  position: relative;
  top: 1.5rem;
}
.login .van-sticky--fixed {
  position: sticky;
}
.login-input {
  position: absolute;
  top: 26%;
  /* left: 24%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 53%;
  width: 100%;
}
.login-input .van-tabs {
  width: 78%;
}
.login-input .van-tab__text {
  font-weight: 900;
  color: #13b5b1;
  font-size: 14px;
}
.login-input .van-cell {
  background-color: #fff !important;
  padding: 0 16px;
}
.login-input .van-cell__value {
  padding: 10px 0;
  border-bottom: 1px solid #e1e1e1;
}
.login-input .van-button {
  width: 59%;
}
.login-input .van-button--info {
  border: none;
}
.why {
  padding: 0.5rem 0;
  position: absolute;
  bottom: 0.05rem;
  left: 1.1rem;
  width: 100%;
}
.why span {
  color: #13b5b1;
}
.login-input .van-tabs__nav {
  background-color: transparent;
}
.language .van-index-anchor {
  background-color: #e1e1e1;
}
.login-input .van-col.van-col--24 {
  padding: 0 0.3rem;
}
.why .van-col.van-col--6 {
  padding: 0 0.5rem 0 0;
}
</style>
